<template>
  <div class="is-relative">
    <p v-if="showMessage" class="has-margin-bottom-100">
      If you contact us by phone, we may ask for your support pin to verify your
      identity.
    </p>
    <div class="has-text-centered">
      <p>
        <pin-input
          class="has-margin-bottom-50"
          :value="user.supportPin"
          :disabled="true"
          :focusable="focusable"
        />
      </p>
      <a class="is-size-7 has-text-grey" @click="generateSupportPin">
        <u>Generate new pin</u>
      </a>
    </div>
    <b-loading :active="isProcessing" :is-full-page="false" />
  </div>
</template>

<script>
export default {
  name: "SupportPinCard",
  props: {
    userId: {
      required: true,
      type: String
    },
    focusable: {
      type: Boolean,
      default: true
    },
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isProcessing: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    }
  },
  created() {
    return this.$store.dispatch("user/observeUser", {
      userId: this.userId
    });
  },
  beforeDestroy() {
    return this.$store.dispatch("user/unobserveUser", {
      userId: this.userId
    });
  },
  methods: {
    generateSupportPin() {
      this.isProcessing = true;
      this.$store
        .dispatch("user/generateSupportPin", { userId: this.userId })
        .then(() => {
          this.$toast.open({
            message: "Pin updated"
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message || "Error generating pin",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.$nextTick(() => {
            this.isProcessing = false;
          });
        });
    }
  }
};
</script>
